import {
  defaultInstance,
  ShopwareApiInstance,
} from '~/shopware-6-client/apiService'

/**
 * @beta
 */
export async function findParcelStation(
  payload: any,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<any> {
  const { data } = await contextInstance.invoke.post(
    '/store-api/bs-packing-station/find-parcel-shop',
    payload
  )

  return data
}
