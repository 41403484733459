<template>
  <base-dialog-card
    :title="$t('packingStation.cardTitle')"
    :action-text="$t('actions.close')"
    @click:close="$emit('click:close')"
  >
    <v-row>
      <v-col cols="12" md="4">
        <base-input-select
          v-model="country"
          :items="countries"
          :label="$t('fields.country')"
          hide-details="auto"
        ></base-input-select>
        <base-input-text-field
          v-model.trim="search"
          :label="$t('packingStation.searchWithYourZipcode')"
          class="mt-3"
          hide-details
          @keyup.enter="invokeSearch"
        ></base-input-text-field>
        <base-button
          rounded
          block
          class="checkout-primary white--text mt-4"
          :loading="loading"
          @click="invokeSearch"
          >{{ $t('packingStation.searchAction') }}</base-button
        >

        <div class="hidden-sm-and-down">
          <template v-if="parcelShops">
            <div v-if="!parcelShops.length" class="text-h4 mt-5 mb-4">
              {{ $t('packingStation.packingStationNotFound') }}
            </div>
            <template v-else>
              <div class="text-h4 mt-5 mb-4">
                {{ $t('packingStation.title') }}
              </div>

              <div style="overflow-y: auto; max-height: 350px">
                <packing-station-address-card
                  v-for="shop in parcelShops"
                  :key="shop.id"
                  class="mb-5"
                  :shop="shop"
                  @click:select="$emit('click:select', $event)"
                ></packing-station-address-card>
              </div>
            </template>
          </template>
        </div>
      </v-col>
      <v-col v-if="parcelShops && parcelShops.length" cols="12" md="8">
        <packing-station-google-map
          :api-key="$config.googleMapsApiKey"
          :markers="markers"
          height="550px"
          @click:select="$emit('click:select', $event)"
        ></packing-station-google-map>
      </v-col>
      <v-col cols="12" class="hidden-md-and-up">
        <template v-if="parcelShops">
          <div v-if="!parcelShops.length" class="text-h4 mb-4">
            {{ $t('packingStation.packingStationNotFound') }}
          </div>
          <template v-else>
            <div class="text-h4 mt-5 mb-4">
              {{ $t('packingStation.title') }}
            </div>

            <packing-station-address-card
              v-for="shop in parcelShops"
              :key="shop.id"
              class="mb-4"
              :shop="shop"
              @click:select="$emit('click:select', $event)"
            ></packing-station-address-card>
          </template>
        </template>
      </v-col>
    </v-row>
  </base-dialog-card>
</template>

<script>
import { computed, ref } from '@nuxtjs/composition-api'
import BaseDialogCard from '~/components/base/BaseDialogCard'
import BaseInputTextField from '~/components/base/input/BaseInputTextField'
import { getApplicationContext, useCountries, useSentry } from '~/composables'
import { findParcelStation } from '~/shopware-6-client/plugins/services/bsPackingStationService'
import PackingStationAddressCard from '~/components/packing-station/PackingStationAddressCard'
import PackingStationGoogleMap from '~/components/packing-station/PackingStationGoogleMap'
import BaseInputSelect from '~/components/base/input/BaseInputSelect'

// TODO: DE darf nicht vorausgewählt sein
export default {
  name: 'PackingStationContainer',
  components: {
    BaseInputSelect,
    PackingStationGoogleMap,
    PackingStationAddressCard,
    BaseInputTextField,
    BaseDialogCard,
  },
  props: {
    initZipcode: {
      type: String,
      default: undefined,
    },
    initCountry: {
      type: String,
      default: undefined,
    },
  },
  setup(props, { root }) {
    const { apiInstance, contextName } = getApplicationContext(
      root,
      'PackingStationContainer'
    )
    const { getCountries } = useCountries(root)
    const { captureClientApiError } = useSentry(root, {
      module: 'vue',
      name: contextName,
    })

    const country = ref(props.initCountry ? props.initCountry : 'DE')
    const search = ref()
    const loading = ref(false)
    const parcelShops = ref(null)

    const invokeSearch = async () => {
      loading.value = true
      try {
        const response = await findParcelStation(
          {
            country: country.value,
            search: search.value,
          },
          apiInstance
        )

        parcelShops.value = response.parcelShops
      } catch (e) {
        captureClientApiError('[vue] findParcelStation', e)

        parcelShops.value = []
      } finally {
        loading.value = false
      }
    }

    const markers = computed(() => {
      return (
        parcelShops.value?.map((parcelShop) => ({
          id: parcelShop.id,
          data: {
            ...parcelShop,
          },
          position: {
            lat: Number(parcelShop.geoLocation.latitude),
            lng: Number(parcelShop.geoLocation.longitude),
          },
        })) || []
      )
    })

    const countries = computed(() => {
      return getCountries.value.map((country) => ({
        text: country?.translated?.name,
        value: country.iso,
      }))
    })

    return {
      country,
      search,
      loading,
      parcelShops,
      markers,
      countries,
      invokeSearch,
    }
  },
  watch: {
    initZipcode: {
      handler(zipcode) {
        this.search = zipcode
      },
      immediate: true,
    },
    initCountry: {
      handler(country) {
        if (country) {
          this.country = country
        }
      },
      immediate: true,
    },
  },
}
</script>
