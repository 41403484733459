<template>
  <div>
    <div class="font-weight-bold">{{ shop.name }}</div>
    <div class="text-body-4">{{ shop.keyword }}</div>
    <address class="text-body-3 mt-2">
      {{ shop.address.street }} {{ shop.address.number }}<br />
      {{ shop.address.zipCode || shop.address.postalCode }}
      {{ shop.address.city }}
    </address>
    <base-button
      small
      rounded
      class="checkout-primary white--text mt-3"
      :class="buttonClass"
      @click="$emit('click:select', shop)"
      >{{ $t('commons.useAddress') }}</base-button
    >
  </div>
</template>

<script>
export default {
  name: 'PackingStationAddressCard',
  props: {
    buttonClass: {
      type: String,
      default: '',
    },
    shop: {
      type: Object,
      required: true,
    },
  },
}
</script>
