<template>
  <div>
    <div ref="googleMap" :style="mapStyle"></div>
    <div ref="infoEle">
      <packing-station-address-card
        v-if="selectedMarker"
        :shop="selectedMarker"
        button-class="d-flex mx-auto"
        @click:select="$emit('click:select', $event)"
      ></packing-station-address-card>
    </div>
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader'
import PackingStationAddressCard from '~/components/packing-station/PackingStationAddressCard'

export default {
  name: 'PackingStationGoogleMap',
  components: { PackingStationAddressCard },
  props: {
    apiKey: {
      type: String,
      required: true,
    },
    markers: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String,
      default: '500px',
    },
  },
  data() {
    return {
      holdMarkers: [],
      google: null,
      map: null,
      infoWindow: null,
      selectedMarker: null,
    }
  },
  computed: {
    mapStyle() {
      return {
        height: this.height,
      }
    },
  },

  watch: {
    markers() {
      this.buildMarkers()
    },
  },

  async mounted() {
    const loader = new Loader({
      apiKey: this.apiKey,
    })

    this.google = await loader.load()

    this.initializeMap()
    this.buildMarkers()
  },

  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMap
      this.map = new this.google.maps.Map(mapContainer, {
        zoom: 14,
      })

      this.infoWindow = new this.google.maps.InfoWindow({
        minWidth: '330px',
      })
    },
    buildMarkers() {
      this.deleteMarkers()

      for (const marker of this.markers) {
        const m = new this.google.maps.Marker({
          position: marker.position,
          id: marker.id,
          map: this.map,
          icon: {
            url: require('~/assets/images/maps/packstation_pfeil.svg'),
            scaledSize: {
              width: 50,
              height: 50,
            },
          },
        })

        m.addListener('click', () => {
          this.openInfoWindow(m, marker)
        })

        this.holdMarkers.push(m)
      }

      if (this.holdMarkers.length) {
        this.map.setCenter(this.holdMarkers[0].position)
      }
    },
    deleteMarkers() {
      for (const marker of this.holdMarkers) {
        marker.setMap(null)
      }

      this.holdMarkers.length = 0
    },
    openInfoWindow(marker, options) {
      if (!this.infoWindow) {
        return
      }

      this.infoWindow.close()

      this.selectedMarker = options.data

      this.$nextTick(() => {
        this.infoWindow.setContent(this.$refs.infoEle)
        this.infoWindow.open({
          anchor: marker,
          map: this.map,
          shouldFocus: false,
        })
      })
    },
  },
}
</script>
